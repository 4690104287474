<ng-container *ngIf="profile$ | async as profile">
  <div class="flex flex-col items-center justify-center gap-4">
    <pt-user-icon
      [src]="profile | profileImage$ | async"
      [diameter]="diameter"
      [name]="profile.name"
     />
    <ng-container *ngIf="canCaptureImage; else uploadOnly">
      <div class="flex items-center justify-center">
        <pt-image-upload
          [iconButton]="true"
          [uploadPath]="storagePath$ | async"
          (imageUpload)="updateImageUrl($event)"
         />
        <button
          mat-icon-button
          (click)="captureImage.next()"
          matTooltip="Capture Image"
        >
          <mat-icon>add_a_photo</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #uploadOnly>
      <pt-image-upload
        [uploadPath]="storagePath$ | async"
        (imageUpload)="updateImageUrl($event)"
       />
    </ng-template>
  </div>
</ng-container>
