import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  isOrganisationWorkspace,
  type WorkspaceType,
} from '@principle-theorem/level-up-core';
import { type IWorkspaceSelector } from '@principle-theorem/ng-auth';
import {
  InputSearchFilter,
  TypedFormControl,
  toSearchInput$,
} from '@principle-theorem/ng-shared';
import { type IUserWorkspace } from '@principle-theorem/shared';
import { sortBy } from 'lodash';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'lu-organisation-workspace-selector',
  templateUrl: './organisation-workspace-selector.component.html',
  styleUrls: ['./organisation-workspace-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationWorkspaceSelectorComponent
  implements IWorkspaceSelector
{
  searchCtrl = new TypedFormControl<string>('');
  workspaceSearch: InputSearchFilter<
    IUserWorkspace<WorkspaceType.Organisation>
  >;
  workspaces$: ReplaySubject<IUserWorkspace<WorkspaceType.Organisation>[]> =
    new ReplaySubject(1);
  @Output()
  workspaceSelected = new EventEmitter<IUserWorkspace>();

  @Input()
  set workspaces(workspaces: IUserWorkspace[]) {
    if (workspaces) {
      this.workspaces$.next(
        sortBy(workspaces.filter(isOrganisationWorkspace), 'workspace')
      );
    }
  }

  constructor() {
    this.workspaceSearch = new InputSearchFilter<
      IUserWorkspace<WorkspaceType.Organisation>
    >(this.workspaces$, toSearchInput$(this.searchCtrl), ['workspace']);
  }
}
