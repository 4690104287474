import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplayCold } from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

type IpifyApiResponse = { ip: string };

@Injectable({
  providedIn: 'root',
})
export class UserPublicIpService {
  private _apiUrl = 'https://api.ipify.org?format=json';

  constructor(private _http: HttpClient) {}

  getIpAddress$(): Observable<string | undefined> {
    return this._http.get<IpifyApiResponse>(this._apiUrl).pipe(
      map((response) => response.ip),
      catchError((error) => {
        // eslint-disable-next-line no-console
        console.error('Failed to get public IP address', error);
        return of(undefined);
      }),
      shareReplayCold()
    );
  }
}
