import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule, type ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { LetDirective, PushPipe } from '@ngrx/component';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { MomentModule } from 'ngx-moment';
import { ActionListButtonComponent } from './action-list-button/action-list-button.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AlertComponent } from './alert/alert.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { BasicDialogService } from './basic-dialog.service';
import { BasicSnackBarService } from './basic-snack-bar.service';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ButtonWithMenuComponent } from './button-with-menu/button-with-menu.component';
import { CharacterCounterComponent } from './character-counter/character-counter.component';
import { CollapsibleContentDirective } from './collapsible/collapsible-content.directive';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { ColourPickerModule } from './colour-picker/colour-picker.module';
import { ColourSliverComponent } from './colour-sliver/colour-sliver.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CSVExporterService } from './csv-exporter.service';
import { CustomTooltipContentDirective } from './custom-tooltip/custom-tooltip-content.directive';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { DateRangeFormComponent } from './date-range-form/date-range-form.component';
import { DefaultTemplateDirective } from './default-template/default-template.directive';
import { DisplayColourPickerComponent } from './display-colour-picker/display-colour-picker.component';
import { DragBoxComponent } from './drag-box/drag-box.component';
import { DynamicComponentPortalDirective } from './dynamic-component/dynamic-component-portal.directive';
import { DynamicComponentComponent } from './dynamic-component/dynamic-component.component';
import { DynamicSidebarFooterComponent } from './dynamic-sidebar/dynamic-sidebar-footer/dynamic-sidebar-footer.component';
import { DynamicSidebarHeaderComponent } from './dynamic-sidebar/dynamic-sidebar-header/dynamic-sidebar-header.component';
import { DynamicSidebarComponent } from './dynamic-sidebar/dynamic-sidebar.component';
import { ElementTargetDirective } from './element-target.directive';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { FileUploadToStorageComponent } from './file-upload-to-storage/file-upload-to-storage.component';
import { FileUploadDropzoneDirective } from './file-upload/file-upload-dropzone.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InitialsIconComponent } from './initials-icon/initials-icon.component';
import { IsoDateSelectorComponent } from './iso-date-selector/iso-date-selector.component';
import { LoaderButtonWithMenuComponent } from './loader-button-with-menu/loader-button-with-menu.component';
import { LoaderButtonComponent } from './loader-button/loader-button.component';
import { LoaderMessageSnackBarComponent } from './loader-message-snack-bar/loader-message-snack-bar.component';
import { LoaderPlaceholderComponent } from './loader-placeholder/loader-placeholder.component';
import { MouseInteractionsService } from './mouse-interactions/mouse-interactions.service';
import { MultiFilterGroupComponent } from './multi-filter-group/multi-filter-group.component';
import { NavigationFocusDirective } from './navigation-focus/navigation-focus.directive';
import { NG_SHARED_CONFIG, type INgSharedConfig } from './ng-shared-config';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageMarginComponent } from './page-margin/page-margin.component';
import { PipesModule } from './pipes/pipes.module';
import { ProfileImageManagerComponent } from './profile-image-manager/profile-image-manager.component';
import { ProfilePicComponent } from './profile-pic/profile-pic.component';
import { RedirectGuard } from './redirect.guard';
import { ResizeSensorDirective } from './resize-sensor.directive';
import { SearchFieldModule } from './search-field/search-field.module';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { SelectListDialogComponent } from './select-list-dialog/select-list-dialog.component';
import { SettingsMenuMultiSelectComponent } from './settings-menu-multi-select/settings-menu-multi-select.component';
import { SettingsMenuSelectComponent } from './settings-menu-select/settings-menu-select.component';
import { SettingsMenuSlideToggleItemComponent } from './settings-menu-slide-toggle-item/settings-menu-slide-toggle-item.component';
import { SettingsMenuTextFieldComponent } from './settings-menu-text-field/settings-menu-text-field.component';
import { SettingsMenuToggleGroupItemComponent } from './settings-menu-toggle-group-item/settings-menu-toggle-group-item.component';
import { TableOfContentsHeadingDirective } from './table-of-contents/table-of-contents-heading.directive';
import { TableOfContentsComponent } from './table-of-contents/table-of-contents.component';
import { TextPromptDialogComponent } from './text-prompt-dialog/text-prompt-dialog.component';
import { TypesenseSearchService } from './typesense/typesense-search.service';
import { UserIconComponent } from './user-icon/user-icon.component';
import { WorkflowUpdateButtonComponent } from './workflow-update-button/workflow-update-button.component';
import { EventColourPickerComponent } from './display-colour-picker/event-colour-picker/event-colour-picker.component';
import { EmojiSelectorComponent } from './emoji-selector/emoji-selector.component';
import { IsoTimeSelectorComponent } from './iso-time-selector/iso-time-selector.component';
import { TruncateTooltipDirective } from './truncate-tooltip.directive';
import { ButtonsContainerComponent } from './buttons-container/buttons-container.component';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialModule,
    ReactiveFormsModule,
    ColourPickerModule,
    SearchFieldModule,
    PipesModule,
    RouterModule,
    ContentLoaderModule,
    MomentModule,
    LetDirective,
    PushPipe,
    EmojiSelectorComponent,
    NgOptimizedImage,
    IsoTimeSelectorComponent,
    ButtonsContainerComponent,
    BreadcrumbsComponent,
    InitialsIconComponent,
    ProfilePicComponent,
    UserIconComponent,
  ],
  declarations: [
    FileUploadComponent,
    FileUploadDropzoneDirective,
    FileUploadToStorageComponent,
    AttachmentsComponent,
    CollapsibleComponent,
    CollapsibleContentDirective,
    ConfirmDialogComponent,
    TextPromptDialogComponent,
    ColourSliverComponent,
    ImageUploadComponent,
    LoaderButtonComponent,
    ImagePreviewComponent,
    CustomTooltipComponent,
    DynamicComponentComponent,
    DynamicComponentPortalDirective,
    AlertDialogComponent,
    DateRangeFormComponent,
    DisplayColourPickerComponent,
    ProfileImageManagerComponent,
    ResizeSensorDirective,
    CustomTooltipContentDirective,
    TableOfContentsComponent,
    TableOfContentsHeadingDirective,
    NavigationFocusDirective,
    DynamicSidebarComponent,
    DynamicSidebarHeaderComponent,
    DynamicSidebarFooterComponent,
    ElementTargetDirective,
    TruncateTooltipDirective,
    SettingsMenuSlideToggleItemComponent,
    SettingsMenuToggleGroupItemComponent,
    SettingsMenuMultiSelectComponent,
    SettingsMenuSelectComponent,
    PageFooterComponent,
    PageMarginComponent,
    ButtonWithMenuComponent,
    FileUploadDialogComponent,
    MultiFilterGroupComponent,
    WorkflowUpdateButtonComponent,
    CharacterCounterComponent,
    LoaderPlaceholderComponent,
    SelectDialogComponent,
    SelectListDialogComponent,
    LoaderMessageSnackBarComponent,
    LoaderButtonWithMenuComponent,
    IsoDateSelectorComponent,
    DragBoxComponent,
    DefaultTemplateDirective,
    AlertComponent,
    EventColourPickerComponent,
    ActionListButtonComponent,
    SettingsMenuTextFieldComponent,
  ],
  exports: [
    ColourPickerModule,
    SearchFieldModule,
    AttachmentsComponent,
    CollapsibleComponent,
    CollapsibleContentDirective,
    ConfirmDialogComponent,
    InitialsIconComponent,
    ProfilePicComponent,
    UserIconComponent,
    PipesModule,
    ColourSliverComponent,
    ImageUploadComponent,
    BreadcrumbsComponent,
    ContentLoaderModule,
    LoaderButtonComponent,
    ImagePreviewComponent,
    CustomTooltipComponent,
    NgMaterialModule,
    DynamicComponentComponent,
    FileUploadComponent,
    FileUploadToStorageComponent,
    DateRangeFormComponent,
    MomentModule,
    DisplayColourPickerComponent,
    ProfileImageManagerComponent,
    LetDirective,
    PushPipe,
    ResizeSensorDirective,
    CustomTooltipContentDirective,
    TableOfContentsComponent,
    NavigationFocusDirective,
    TableOfContentsHeadingDirective,
    DynamicSidebarComponent,
    DynamicSidebarHeaderComponent,
    DynamicSidebarFooterComponent,
    ElementTargetDirective,
    TruncateTooltipDirective,
    SettingsMenuSlideToggleItemComponent,
    SettingsMenuToggleGroupItemComponent,
    SettingsMenuMultiSelectComponent,
    SettingsMenuSelectComponent,
    PageFooterComponent,
    ButtonWithMenuComponent,
    FileUploadDialogComponent,
    MultiFilterGroupComponent,
    WorkflowUpdateButtonComponent,
    CharacterCounterComponent,
    LoaderPlaceholderComponent,
    LoaderMessageSnackBarComponent,
    FileUploadDropzoneDirective,
    LoaderButtonWithMenuComponent,
    IsoDateSelectorComponent,
    DragBoxComponent,
    DefaultTemplateDirective,
    AlertComponent,
    UserIconComponent,
    EventColourPickerComponent,
    ActionListButtonComponent,
    SettingsMenuTextFieldComponent,
    EmojiSelectorComponent,
    NgOptimizedImage,
    IsoTimeSelectorComponent,
    ButtonsContainerComponent,
  ],
  providers: [
    MouseInteractionsService,
    BasicDialogService,
    BasicSnackBarService,
    CSVExporterService,
    TypesenseSearchService,
    RedirectGuard,
  ],
})
export class NgSharedModule {
  static forRoot(config: INgSharedConfig): ModuleWithProviders<NgSharedModule> {
    return {
      ngModule: NgSharedModule,
      providers: [
        {
          provide: NG_SHARED_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
