import { Injectable } from '@angular/core';
import { type UrlTree } from '@angular/router';
import { AuthGuard, AuthService } from '@principle-theorem/ng-auth';
import { filterUndefined, snapshot } from '@principle-theorem/shared';
import { ManagementService } from './management.service';

@Injectable({
  providedIn: 'root',
})
export class IsManagementGuard extends AuthGuard {
  constructor(auth: AuthService, private _management: ManagementService) {
    super(auth);
  }

  async canActivate(): Promise<boolean | UrlTree> {
    const user = await snapshot(this._management.user$.pipe(filterUndefined()));
    return user ? true : false;
  }
}
