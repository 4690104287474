<div class="flex flex-col items-center gap-4 p-4">
  <h3>Select a Workspace</h3>
  <mat-form-field>
    <input
      matInput
      placeholder="Search Workspaces"
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (
        workspace of workspaceSearch.results$ | async;
        track workspace.uid
      ) {
        <mat-option
          [value]="workspace"
          (click)="workspaceSelected.emit(workspace)"
          >{{ workspace.workspace }}</mat-option
        >
      }
    </mat-autocomplete>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
