import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Organisation,
  type IOrganisation,
  DEFAULT_USER_GROUP_UID,
  IUserGroup,
  OrganisationCollection,
  getDefaultGroup,
} from '@principle-theorem/level-up-core';
import { AuthFirebaseFunctionsService } from '@principle-theorem/ng-auth';
import {
  DialogPresets,
  InputSearchFilter,
  TrackByFunctions,
  TypedFormControl,
} from '@principle-theorem/ng-shared';
import {
  addDoc,
  multiSortBy$,
  nameSorter,
  subCollection,
  type WithRef,
} from '@principle-theorem/shared';
import { startWith } from 'rxjs/operators';
import { OrganisationAddDialogComponent } from '../organisation-add-dialog/organisation-add-dialog.component';

@Component({
  selector: 'lu-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationsComponent {
  trackByOrganisation = TrackByFunctions.ref<WithRef<IOrganisation>>();
  search: TypedFormControl<string> = new TypedFormControl<string>('');
  searchFilter: InputSearchFilter<WithRef<IOrganisation>>;

  constructor(
    private _authFunctions: AuthFirebaseFunctionsService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.searchFilter = new InputSearchFilter<WithRef<IOrganisation>>(
      Organisation.all$().pipe(multiSortBy$(nameSorter())),
      this.search.valueChanges.pipe(startWith('')),
      ['name']
    );
  }

  async addOrganisation(): Promise<void> {
    const organisationData = await this._dialog
      .open<
        OrganisationAddDialogComponent,
        undefined,
        Pick<IOrganisation, 'name'>
      >(OrganisationAddDialogComponent, DialogPresets.small())
      .afterClosed()
      .toPromise();

    if (!organisationData || !organisationData.name) {
      return;
    }

    const organisation = Organisation.init(organisationData);

    const alreadyExists: boolean =
      await this._authFunctions.checkOrganisationExists(organisation.slug);

    if (alreadyExists) {
      this._snackBar.open('Organisation with this name already exists');
      return;
    }

    const orgRef = await addDoc(Organisation.col(), organisation);
    await addDoc(
      subCollection<IUserGroup>(orgRef, OrganisationCollection.UserGroups),
      getDefaultGroup(),
      DEFAULT_USER_GROUP_UID
    );
    this._snackBar.open('Organisation Added');
  }
}
