import { Injectable, type OnDestroy } from '@angular/core';
import {
  IFolder,
  VendorBundle,
  type IBundlePathway,
  type IBundleSkill,
  type IMarketplaceSubscription,
  type IPathway,
  type IRoutableSkill,
  type ISkill,
  type ITag,
  type IUser,
  type IVendorBundle,
} from '@principle-theorem/level-up-core';
import {
  DocumentReference,
  doc$,
  multiFilter,
  multiMap,
  multiSwitchMap,
  reduceToSingleArray,
  shareReplayHot,
  snapshot,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { pick } from 'lodash';
import { Subject, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from './organisation.service';

export interface IBundleWithSubscription {
  bundle: WithRef<IVendorBundle>;
  subscription: WithRef<IMarketplaceSubscription>;
}
@Injectable({
  providedIn: 'root',
})
export class CachedListsService implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  users$: Observable<WithRef<IUser>[]>;
  tags$: Observable<WithRef<ITag>[]>;
  folders$: Observable<WithRef<IFolder>[]>;
  skills$: Observable<WithRef<ISkill>[]>;
  pathways$: Observable<WithRef<IPathway>[]>;
  workspaceCreatedSkills$: Observable<WithRef<ISkill>[]>;
  subscriptions$: Observable<WithRef<IMarketplaceSubscription>[]>;
  subscriptionBundlePair$: Observable<IBundleWithSubscription[]>;
  marketplaceSkills$: Observable<WithRef<IBundleSkill>[]>;
  marketplacePathways$: Observable<WithRef<IBundlePathway>[]>;
  routableSkills$: Observable<
    Record<string, IRoutableSkill & IReffable<ISkill>>
  >;

  constructor(private _org: OrganisationService) {
    this.tags$ = this._org.tags$;
    this.folders$ = this._org.folders$;
    this.skills$ = this._org.skills$;
    this.workspaceCreatedSkills$ = this.skills$.pipe(
      multiFilter((skill) => !skill.vendorSkillRef)
    );
    this.pathways$ = this._org.pathways$;
    this.users$ = this._org.users$;
    this.subscriptions$ = this._org.marketplaceSubscriptions$;
    this.subscriptionBundlePair$ = this.subscriptions$.pipe(
      multiSwitchMap((subscription) => {
        return doc$(subscription.bundleRef).pipe(
          map((bundle) => ({
            bundle,
            subscription,
          }))
        );
      })
    );

    this.marketplaceSkills$ = this.subscriptionBundlePair$.pipe(
      multiSwitchMap((bundlePair) => VendorBundle.skills$(bundlePair.bundle)),
      map(reduceToSingleArray)
    );

    this.marketplacePathways$ = this.subscriptionBundlePair$.pipe(
      multiSwitchMap((bundlePair) => VendorBundle.pathways$(bundlePair.bundle)),
      map(reduceToSingleArray)
    );

    this.routableSkills$ = this.skills$.pipe(
      multiMap((skill) => {
        const reffableSkill: IRoutableSkill & IReffable<ISkill> = pick(skill, [
          'name',
          'readOnly',
          'author',
          'restrictAccessTo',
          'ref',
        ]);

        return {
          [skill.ref.id]: reffableSkill,
        };
      }),
      map((skills) =>
        skills.reduce(
          (allSkills, daySkill) => ({ ...allSkills, ...daySkill }),
          {}
        )
      ),
      shareReplayHot(this._onDestroy$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getLocalSkill(
    skillRef: DocumentReference<ISkill>
  ): Promise<WithRef<ISkill> | undefined> {
    return snapshot(this._org.getSkill$(skillRef));
  }

  getLocalSkill$(
    skillRef: DocumentReference<ISkill>
  ): Observable<WithRef<ISkill> | undefined> {
    return this._org.getSkill$(skillRef);
  }

  getPathway$(
    pathwayRef: DocumentReference<IPathway>
  ): Observable<WithRef<IPathway> | undefined> {
    return this._org.getPathway$(pathwayRef);
  }
}
