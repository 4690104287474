import { first, isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { shareReplayHot, snapshot } from '../../rxjs';
import { serialise } from '../../serialisers/primitive-object-serialiser';
import { WithRef } from '../interfaces';
import {
  CollectionReference,
  DocumentReference,
  Query,
  QueryConstraint,
} from './adaptor/firestore.node';
import { query$ } from './collection';
import { Firestore } from './firestore';
import { map } from 'rxjs/operators';

export class FirestoreCache<T extends object> {
  items$: { [key: string]: Observable<WithRef<T>> } = {};

  constructor(private _onDestroy$: Observable<void> = AppDestroy.onDestroy$) {}

  doc$(ref: DocumentReference<T>): Observable<WithRef<T>> {
    if (!this.items$[ref.path]) {
      this.items$[ref.path] = Firestore.doc$(ref).pipe(
        shareReplayHot(this._onDestroy$)
      );
    }

    return this.items$[ref.path];
  }

  getDoc(ref: DocumentReference<T>): Promise<WithRef<T>> {
    return snapshot(this.doc$(ref));
  }
}

export class FirestoreQueryCache<T extends object> {
  queries$: {
    properties: object;
    results$: Observable<WithRef<T>[]>;
  }[] = [];

  constructor(private _onDestroy$: Observable<void> = AppDestroy.onDestroy$) {}

  query$(
    properties: object,
    col: CollectionReference<T> | Query<T> | string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<WithRef<T>[]> {
    const serialisedProperties = serialise(properties);
    const existingQuery = this.queries$.find((query) =>
      isEqual(query.properties, serialisedProperties)
    );

    if (existingQuery) {
      return existingQuery.results$;
    }

    const query = {
      properties: serialisedProperties,
      results$: query$(col, ...queryConstraints).pipe(
        shareReplayHot(this._onDestroy$)
      ),
    };
    this.queries$.push(query);
    return query.results$;
  }

  find$(
    properties: object,
    col: CollectionReference<T> | Query<T> | string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<WithRef<T> | undefined> {
    return this.query$(properties, col, ...queryConstraints).pipe(
      map((items) => first(items))
    );
  }
}

export class AppDestroy {
  private static _onDestroy$ = new Subject<void>();
  static onDestroy$ = this._onDestroy$.asObservable();

  static destroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
