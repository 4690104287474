import {
  type AtLeast,
  type WithRef,
  all$,
  collectionGroupQuery,
  doc$,
  firstResult$,
  getParentDocRef,
  isObject,
  subCollection,
  firstResult,
  IReffable,
} from '@principle-theorem/shared';
import {
  type CollectionReference,
  type DocumentReference,
  orderBy,
  where,
} from '@principle-theorem/shared';
import { compact } from 'lodash';
import {
  type Observable,
  type OperatorFunction,
  combineLatest,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RootCollection } from '../../root-collection';
import { type IOrganisation } from '../organisation/organisation';
import { OrganisationCollection } from '../organisation/organisation-collections';
import {
  BundleReleaseStatus,
  type IBundlePathway,
  type IBundleRelease,
  type IBundleSkill,
  type IBundleTag,
  type IBundleTemplate,
} from './bundle';
import {
  type IMarketplaceSubscription,
  type IMarketplaceSubscriptionSummary,
} from './marketplace-subscription';
import { type IVendor, VendorCollection } from './vendor';

export interface IVendorBundle {
  name: string;
  description: string;
  addToNewWorkspaces: boolean;
  readOnly: boolean;
  assignToDefaultGroup: boolean;
  visibility: BundleVisibility;
  imageUrl?: string;
}

export enum BundleVisibility {
  Public = 'public',
  Private = 'private',
}

export function isVendorBundle(item: unknown): item is IVendorBundle {
  return (
    isObject(item) &&
    'name' in item &&
    'description' in item &&
    'addToNewWorkspaces' in item &&
    typeof item.addToNewWorkspaces === 'boolean' &&
    'readOnly' in item &&
    typeof item.readOnly === 'boolean' &&
    'assignToDefaultGroup' in item &&
    typeof item.assignToDefaultGroup === 'boolean'
  );
}

export enum VendorBundleCollection {
  Pathways = 'pathways',
  Releases = 'releases',
  Skills = 'skills',
  Templates = 'templates',
  Tags = 'tags',
}

export class VendorBundle {
  static init(overrides: AtLeast<IVendorBundle, 'name'>): IVendorBundle {
    return {
      description: '',
      addToNewWorkspaces: false,
      assignToDefaultGroup: false,
      readOnly: false,
      visibility: BundleVisibility.Private,
      ...overrides,
    };
  }

  static pathwayCol(
    bundle: IReffable<IVendorBundle>
  ): CollectionReference<IBundlePathway> {
    return subCollection<IBundlePathway>(
      bundle.ref,
      VendorBundleCollection.Pathways
    );
  }

  static pathways$(
    bundle: IReffable<IVendorBundle>
  ): Observable<WithRef<IBundlePathway>[]> {
    return all$(VendorBundle.pathwayCol(bundle));
  }

  static skillCol(
    bundle: WithRef<IVendorBundle>
  ): CollectionReference<IBundleSkill> {
    return subCollection<IBundleSkill>(
      bundle.ref,
      VendorBundleCollection.Skills
    );
  }

  static skills$(
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<IBundleSkill>[]> {
    return all$(VendorBundle.skillCol(bundle));
  }

  static templateCol(
    bundle: WithRef<IVendorBundle>
  ): CollectionReference<IBundleTemplate> {
    return subCollection<IBundleTemplate>(
      bundle.ref,
      VendorBundleCollection.Templates
    );
  }

  static templates$(
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<IBundleTemplate>[]> {
    return all$(VendorBundle.templateCol(bundle));
  }

  static tagCol(
    bundle: WithRef<IVendorBundle>
  ): CollectionReference<IBundleTag> {
    return subCollection<IBundleTag>(bundle.ref, VendorBundleCollection.Tags);
  }

  static tags$(
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<IBundleTag>[]> {
    return all$(VendorBundle.tagCol(bundle));
  }

  static releaseCol(
    bundle: WithRef<IVendorBundle>
  ): CollectionReference<IBundleRelease> {
    return subCollection<IBundleRelease>(
      bundle.ref,
      VendorBundleCollection.Releases
    );
  }

  static releases$(
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<IBundleRelease>[]> {
    return all$(VendorBundle.releaseCol(bundle));
  }

  static storagePath(
    vendorRef: DocumentReference<IVendor>,
    bundleRef: DocumentReference<IVendorBundle>
  ): string {
    return `${RootCollection.MarketplaceVendors}/${vendorRef.id}/${VendorCollection.Bundles}/${bundleRef.id}`;
  }

  static subscribers$(
    bundle: WithRef<IVendorBundle>
  ): Observable<IMarketplaceSubscriptionSummary[]> {
    return all$(
      collectionGroupQuery<IMarketplaceSubscription>(
        OrganisationCollection.MarketplaceSubscriptions,
        where('bundleRef', '==', bundle.ref)
      )
    ).pipe(
      switchMap((subscriptions) => {
        if (!subscriptions.length) {
          return of([]);
        }
        return combineLatest(
          compact(
            subscriptions.map((subscription) =>
              getSubscriptionSummary(subscription)
            )
          )
        );
      })
    );
  }

  static resolveLatestRelease$(
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<IBundleRelease> | undefined> {
    return firstResult$(
      VendorBundle.releaseCol(bundle),
      where('status', '==', BundleReleaseStatus.Released),
      orderBy('releasedAt', 'desc')
    );
  }

  static resolveLatestRelease(
    bundle: WithRef<IVendorBundle>
  ): Promise<WithRef<IBundleRelease> | undefined> {
    return firstResult(
      VendorBundle.releaseCol(bundle),
      where('status', '==', BundleReleaseStatus.Released),
      orderBy('releasedAt', 'desc')
    );
  }
}

function getSubscriptionSummary(
  subscription: WithRef<IMarketplaceSubscription>
): Observable<IMarketplaceSubscriptionSummary> | undefined {
  const organisationRef = getParentDocRef<IOrganisation>(subscription.ref);
  if (!organisationRef) {
    return;
  }

  return doc$(organisationRef).pipe(
    map((subscriber) => ({
      subscription,
      subscriber,
    }))
  );
}

export function resolveLatestRelease(): OperatorFunction<
  WithRef<IVendorBundle>,
  WithRef<IBundleRelease> | undefined
> {
  return (bundle$) =>
    bundle$.pipe(
      switchMap((bundle) => VendorBundle.resolveLatestRelease$(bundle))
    );
}
