import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitCamel',
})
export class SplitCamelPipe implements PipeTransform {
  transform(value: string): string {
    return splitCamel(value);
  }
}

export function splitCamel(value: string): string {
  return value
    .split('')
    .reduce(
      (
        output: string,
        character: string,
        index: number,
        chartacters: string[]
      ) => {
        if (
          !new RegExp(/[A-Z]$/).exec(output) &&
          new RegExp(/^[A-Z]/).exec(character)
        ) {
          return output + ` ${character}`;
        }

        if (
          new RegExp(/^[A-Z]/).exec(character) &&
          chartacters[index + 1] &&
          !new RegExp(/[A-Z]$/).exec(chartacters[index + 1])
        ) {
          return output.concat(` ${character}`);
        }

        return output.concat(character);
      },
      ''
    )
    .trim();
}
