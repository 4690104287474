import { Injectable, type OnDestroy } from '@angular/core';
import {
  IFolder,
  Organisation,
  Pathway,
  RootCollection,
  Skill,
  UserGroup,
  WorkspaceType,
  type IMarketplaceSubscription,
  type IOrganisation,
  type IPageTemplate,
  type IPathway,
  type ISkill,
  type ITag,
  type IUser,
  type IUserGroup,
} from '@principle-theorem/level-up-core';
import { AuthService, WorkspaceService } from '@principle-theorem/ng-auth';
import {
  DocumentReference,
  filterUndefined,
  getDoc$,
  isChanged$,
  isPathChanged$,
  isSameRef,
  multiFind,
  multiSortBy$,
  nameSorter,
  query$,
  shareReplayCold,
  shareReplayHot,
  where,
  type CollectionReference,
  type IReffable,
  type WithRef,
} from '@principle-theorem/shared';
import { Dictionary, keyBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Subject, combineLatest, noop, of, type Observable } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { UserSyncBloc } from './user-sync-bloc';
import { ManagementService } from '../auth/management.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService implements OnDestroy {
  private _onDestroy$: Subject<void> = new Subject<void>();
  organisation$: Observable<WithRef<IOrganisation> | undefined>;
  pathwayCol$: Observable<CollectionReference<IPathway>>;
  pathways$: Observable<WithRef<IPathway>[]>;
  pathwayMap$: Observable<Dictionary<WithRef<IPathway>>>;
  skillCol$: Observable<CollectionReference<ISkill>>;
  skills$: Observable<WithRef<ISkill>[]>;
  skillMap$: Observable<Dictionary<WithRef<ISkill>>>;
  pageTemplatesCol$: Observable<CollectionReference<IPageTemplate>>;
  pageTemplates$: Observable<WithRef<IPageTemplate>[]>;
  tagCol$: Observable<CollectionReference<ITag>>;
  tags$: Observable<WithRef<ITag>[]>;
  folderCol$: Observable<CollectionReference<IFolder>>;
  folders$: Observable<WithRef<IFolder>[]>;
  userGroupsCol$: Observable<CollectionReference<IUserGroup>>;
  userGroups$: Observable<WithRef<IUserGroup>[]>;
  usersCol$: Observable<CollectionReference<IUser>>;
  users$: Observable<WithRef<IUser>[]>;
  marketplaceSubscriptionsCol$: Observable<
    CollectionReference<IMarketplaceSubscription>
  >;
  marketplaceSubscriptions$: Observable<WithRef<IMarketplaceSubscription>[]>;
  storagePath$: Observable<string>;
  user$: Observable<WithRef<IUser> | undefined>;
  associatedGroups$: Observable<WithRef<IUserGroup>[]>;
  enabledUsers$: Observable<WithRef<IUser>[]>;

  constructor(
    private _auth: AuthService,
    private _workspace: WorkspaceService,
    private _management: ManagementService
  ) {
    this.organisation$ = combineLatest([
      this._auth.authUser$,
      this._workspace.value$,
    ]).pipe(
      switchMap(([user, workspace]) => {
        if (
          !user ||
          !workspace ||
          !workspace.uid ||
          workspace.type !== String(WorkspaceType.Organisation)
        ) {
          return of(undefined);
        }

        return getDoc$(Organisation.col(), workspace.uid).pipe(
          catchError(() => of(undefined))
        );
      }),
      shareReplayHot(this._onDestroy$)
    );

    this.usersCol$ = this._toOrganisationCol$(Organisation.userCol);
    this.users$ = this._resolveOrganisationCol$(Organisation.users$).pipe(
      multiSortBy$(nameSorter()),
      shareReplayHot(this._onDestroy$)
    );

    this.enabledUsers$ = this.usersCol$.pipe(
      switchMap((userCol: CollectionReference<IUser>) => {
        return query$(userCol, where('isActivated', '==', true));
      }),
      multiSortBy$(nameSorter()),
      shareReplayHot(this._onDestroy$)
    );

    const userBloc = new UserSyncBloc(
      this.usersCol$,
      this._auth.authUser$,
      this._onDestroy$
    );
    this.user$ = this._management.user$.pipe(
      switchMap((managementUser) => {
        if (managementUser) {
          return of(managementUser);
        }

        return userBloc.user$;
      }),
      shareReplayHot(this._onDestroy$)
    );

    this.userGroupsCol$ = this._toOrganisationCol$(Organisation.userGroupCol);
    this.userGroups$ = this._resolveOrganisationCol$(
      Organisation.userGroups$
    ).pipe(multiSortBy$(nameSorter()), shareReplayHot(this._onDestroy$));

    this.associatedGroups$ = combineLatest([
      this.user$.pipe(isPathChanged$('ref.path')),
      this.userGroups$,
    ]).pipe(
      map(([user, groups]) => {
        if (!user) {
          return [];
        }
        return groups.filter((group) => UserGroup.hasUser(group, user));
      }),
      shareReplayHot(this._onDestroy$)
    );

    combineLatest([
      this._auth.authToken$.pipe(
        map((token) => token?.issuedAtTime),
        filterUndefined()
      ),
      this.user$.pipe(
        map((user) => user?.lastForceTokenRefresh),
        filterUndefined()
      ),
    ])
      .pipe(
        map(([tokenIssueTime, lastRefreshTime]) =>
          moment(tokenIssueTime).isBefore(lastRefreshTime.toDate())
        ),
        switchMap(async (updateUserClaims) => {
          if (updateUserClaims) {
            return this._auth.loadClaims();
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);

    this.marketplaceSubscriptionsCol$ = this._toOrganisationCol$(
      Organisation.subscriptionCol
    );
    this.marketplaceSubscriptions$ = this._resolveOrganisationCol$(
      Organisation.subscriptions$
    );

    this.pathwayCol$ = this._toOrganisationCol$(Organisation.pathwayCol);
    this.pathways$ = combineLatest([
      this._resolveOrganisationCol$(Organisation.pathways$),
      this.user$,
      this.associatedGroups$,
    ]).pipe(
      map(([pathways, user, groups]) =>
        pathways.filter((pathway) =>
          user ? Pathway.canView(pathway, user, groups) : false
        )
      ),
      multiSortBy$(nameSorter()),
      shareReplayHot(this._onDestroy$)
    );

    this.skillCol$ = this._toOrganisationCol$(Organisation.skillCol).pipe(
      shareReplayHot(this._onDestroy$)
    );
    this.skills$ = combineLatest([
      this._resolveOrganisationCol$(Organisation.skills$),
      this.user$.pipe(isPathChanged$('ref.path')),
      this.associatedGroups$.pipe(isChanged$(isSameRef)),
    ]).pipe(
      map(([skills, user, groups]) =>
        skills.filter((skill) =>
          user ? Skill.canView(skill, user, groups) : false
        )
      ),
      multiSortBy$(nameSorter()),
      shareReplayHot(this._onDestroy$)
    );

    this.skillMap$ = this.skills$.pipe(
      map((skills) => keyBy(skills, (skill) => skill.ref.path))
    );

    this.pathwayMap$ = this.pathways$.pipe(
      map((pathways) => keyBy(pathways, (pathway) => pathway.ref.path))
    );

    this.pageTemplatesCol$ = this._toOrganisationCol$(
      Organisation.pageTemplateCol
    );
    this.pageTemplates$ = this._resolveOrganisationCol$(
      Organisation.pageTemplates$
    ).pipe(multiSortBy$(nameSorter()));

    this.tagCol$ = this._toOrganisationCol$(Organisation.tagCol);
    this.tags$ = this._resolveOrganisationCol$(Organisation.tags$).pipe(
      multiSortBy$(nameSorter())
    );

    this.folderCol$ = this._toOrganisationCol$(Organisation.folderCol);
    this.folders$ = this._resolveOrganisationCol$(Organisation.folders$).pipe(
      multiSortBy$(nameSorter())
    );

    this.storagePath$ = this.organisation$.pipe(
      filterUndefined(),
      map((org) => `${RootCollection.Organisations}/${org.ref.id}`),
      shareReplayHot(this._onDestroy$)
    );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getUser$(
    userRef: DocumentReference<IUser>
  ): Observable<WithRef<IUser> | undefined> {
    return this.users$.pipe(multiFind((user) => isSameRef(user, userRef)));
  }

  getSkill$(
    skillRef: DocumentReference<ISkill>
  ): Observable<WithRef<ISkill> | undefined> {
    return this.skillMap$.pipe(map((skillMap) => skillMap[skillRef.path]));
  }

  getPathway$(
    pathwayRef: DocumentReference<IPathway>
  ): Observable<WithRef<IPathway> | undefined> {
    return this.pathwayMap$.pipe(
      map((pathwayMap) => pathwayMap[pathwayRef.path])
    );
  }

  private _toOrganisationCol$<T>(
    colFn: (org: IReffable<IOrganisation>) => CollectionReference<T>
  ): Observable<CollectionReference<T>> {
    return this.organisation$.pipe(
      filterUndefined(),
      map(colFn),
      shareReplayCold()
    );
  }

  private _resolveOrganisationCol$<T>(
    query: (org: IReffable<IOrganisation>) => Observable<T[]>
  ): Observable<T[]> {
    return this.organisation$.pipe(
      filterUndefined(),
      switchMap(query),
      shareReplayHot(this._onDestroy$)
    );
  }
}
