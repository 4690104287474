<div
  class="items-between my-2 flex grow flex-col items-center justify-center gap-4"
>
  <mat-nav-list>
    <a
      mat-list-item
      class="text-center"
      routerLinkActive="active"
      ptIntercomTarget="sidebar.home"
      matTooltip="Home"
      [routerLink]="['/home']"
    >
      <mat-icon>home</mat-icon>
    </a>
    <a
      href="javascript:void(0);"
      class="text-center"
      mat-list-item
      matTooltip="Search Everything"
    >
      <lu-search-button />
    </a>
    <div
      *ngIf="
        notificationsCount$ | async as notificationsCount;
        else noNotifications
      "
      [matBadge]="notificationsCount >= 10 ? '10+' : notificationsCount"
      matBadgeColor="warn"
    >
      <a
        href="javascript:void(0);"
        class="text-center"
        mat-list-item
        *ngIf="
          notificationsCount$ | async as notificationsCount;
          else noNotifications
        "
        matTooltip="Notifications"
      >
        <button mat-icon-button (click)="openNotifications($event)">
          <mat-icon> notifications </mat-icon>
        </button>
      </a>
    </div>
    <ng-template #noNotifications>
      <a href="javascript:void(0);" class="text-center" mat-list-item>
        <button mat-icon-button (click)="openNotifications($event)">
          <mat-icon>notifications</mat-icon>
        </button>
      </a>
    </ng-template>
  </mat-nav-list>

  <span class="grow"></span>

  <div class="flex flex-col items-center justify-center">
    <mat-nav-list>
      <ng-container
        *ngFor="let group of groups$ | async; trackBy: trackByGroup"
      >
        <ng-container *ngIf="group.icon; else noGroupIcon">
          <a
            href="javascript:void(0);"
            mat-list-item
            class="text-center"
            routerLinkActive="active"
            ptIntercomTarget="sidebar.{{ group.name }}"
            [matTooltip]="group.name"
            [matMenuTriggerFor]="moveItemsMenu"
          >
            <mat-icon>{{ group.icon }}</mat-icon>
          </a>
          <mat-menu
            class="sidebar-menu"
            #moveItemsMenu="matMenu"
            xPosition="after"
          >
            <ng-template matMenuContent>
              <a
                *ngFor="let link of group.links; trackBy: trackByLink"
                mat-menu-item
                class="text-center"
                routerLinkActive="active"
                ptIntercomTarget="sidebar.{{ link.name }}"
                [routerLink]="link.path"
                [matTooltip]="link.description"
              >
                <mat-icon *ngIf="link.icon">{{ link.icon }}</mat-icon>
                <span>{{ link.name }}</span>
              </a>
            </ng-template>
          </mat-menu>
        </ng-container>

        <ng-template #noGroupIcon>
          <h2 class="mat-headline-6" *ngIf="group.name">{{ group.name }}</h2>
          <ng-container *ngFor="let link of group.links; trackBy: trackByLink">
            <a
              mat-list-item
              class="text-center"
              routerLinkActive="active"
              ptIntercomTarget="sidebar.{{ link.name }}"
              [routerLink]="link.path"
              [matTooltip]="link.description"
            >
              <ng-container *ngIf="link.icon; else noLinkIcon">
                <mat-icon>{{ link.icon }}</mat-icon>
              </ng-container>

              <ng-template #noLinkIcon>
                {{ link.name }}
              </ng-template>
              <div *ngIf="link.action" matListItemMeta>
                <button
                  mat-icon-button
                  (click)="runAction($event, link.action)"
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </a>
          </ng-container>
        </ng-template>
      </ng-container>
      <a
        class="profile"
        href="javascript:void(0);"
        mat-list-item
        *ngIf="orgUser$ | async as user"
      >
        <lu-user-profile-menu [user]="user" />
      </a>
    </mat-nav-list>
    <button
      mat-mini-fab
      class="!min-h-[40px]"
      id="intercom-launcher"
      aria-label="Customer Support Button"
      matTooltip="Customer Support"
    >
      <mat-icon class="intercom-icon">question_answer</mat-icon>
    </button>
  </div>
</div>
