import * as moment from 'moment-timezone';
import { type Timezone } from './timezone';

/**
 * Example
 * 'Monday', 'Tuesday'
 */
export const DAY_OF_WEEK_FORMAT = 'dddd';

export const DAY_OF_MONTH_FORMAT = 'Do';
export const DAY_NUMBER_OF_MONTH_FORMAT = 'D';
export const MONTH_FORMAT = 'MMMM';
export const YEAR_FORMAT = 'YYYY';

export const DATE_FORMATS_DMY = [
  'D/M/YY',
  'D/M/YYYY',
  'D/MM/YY',
  'D/MM/YYYY',
  'DD/M/YY',
  'DD/M/YYYY',
  'DD/MM/YY',
  'DD/MM/YYYY',
  'D-M-YY',
  'D-M-YYYY',
  'D-MM-YY',
  'D-MM-YYYY',
  'DD-M-YY',
  'DD-M-YYYY',
  'DD-MM-YY',
  'DD-MM-YYYY',
  'D.M.YY',
  'D.M.YYYY',
  'D.MM.YY',
  'D.MM.YYYY',
  'DD.M.YY',
  'DD.M.YYYY',
  'DD.MM.YY',
  'DD.MM.YYYY',
];

/**
 * Examples
 * 1:00am
 * 1:00pm
 */
export const TIME_FORMAT = 'h:mma';

/**
 * Examples
 * 01:00
 * 13:00
 */
export const TIME_FORMAT_24HR = 'HH:mm';

/**
 * Examples
 * Sunday, 3rd November
 */
export const DATE_FORMAT = 'dddd, Do MMMM';

/**
 * Examples
 * 12 Apr
 */
export const COMPACT_DATE_FORMAT = 'D MMM';

/**
 * Examples
 * Mon 12th Apr
 */
export const CASUAL_DATE_FORMAT = 'ddd Do MMM';

/**
 * Examples
 * Monday, 12th
 */
export const CASUAL_DAY_FORMAT = `${DAY_OF_WEEK_FORMAT}, ${DAY_OF_MONTH_FORMAT}`;

/**
 * Examples
 * 12th April 2021
 */
export const DATE_WITH_YEAR = `Do MMM YYYY`;

/**
 * Examples
 * Monday, 12th April 2021
 */
export const CASUAL_DATE_WITH_YEAR = `${CASUAL_DAY_FORMAT} MMMM YYYY`;

/**
 * Examples
 * Sun 3rd Nov @ 1:00 AM
 * Mon 4th Nov @ 11:00 PM
 */
export const DATE_TIME_FORMAT = 'ddd Do MMM @ h:mm A';

/**
 * Examples
 * 03/11/22 @ 1:00am
 * 04/11/22 @ 11:00pm
 */
export const SHORT_DATE_TIME_FORMAT = `DD/MM/YY @ ${TIME_FORMAT}`;

/**
 * Examples
 * Sun 3rd Nov 2020 @ 1:00 AM
 * Mon 4th Nov 2020 @ 11:00 PM
 */
export const DATE_TIME_WITH_YEAR_FORMAT = 'ddd, Do MMM YYYY @ h:mm A';

/**
 * Examples
 * Sun 3/11/2020 @ 1:00am
 * Mon 4/11/2020 @ 11:00pm
 */
export const SHORT_DATE_TIME_WITH_YEAR_FORMAT = `ddd, ${SHORT_DATE_TIME_FORMAT}`;

/**
 * Example
 * 22/05/2019
 */
export const DAY_MONTH_YEAR_FORMAT = 'DD/MM/YYYY';

/**
 * Same as above but for Angular Date pipe
 */
export const DAY_MONTH_YEAR_DATE_FORMAT = 'dd/MM/YYYY';

/**
 * Example
 * 05/2019
 */
export const MONTH_YEAR_FORMAT = 'MM/YYYY';

/**
 * Example
 * 2019-05-22
 */
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Example
 * 14:10:35
 */
export const ISO_TIME_FORMAT = 'HH:mm:ss';

/**
 * Example
 * 2019-05-22 14:10:35
 */
export const ISO_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT} ${ISO_TIME_FORMAT}`;

/**
 * Examples
 * 3rd Nov, 2018 • 1:00 am
 * 4th Nov, 2018 • 11:00 pm
 */
export const HISTORY_DATE_FORMAT = 'Do MMM, Y';
export const HISTORY_DATE_TIME_FORMAT = `${HISTORY_DATE_FORMAT} • h:mm a`;

export const CALENDAR_FORMAT: Record<string, string> = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: CASUAL_DATE_FORMAT,
};

export const CALENDAR_TIME_FORMAT: Record<string, string> = {
  sameDay: '[Today @] h:mm A',
  nextDay: '[Tomorrow @] h:mm A',
  nextWeek: '[This] dddd Do of MMMM @ h:mm A',
  lastDay: '[Yesterday @] h:mm A',
  lastWeek: '[Last] dddd Do of MMMM @ h:mm A',
  sameElse: 'dddd Do of MMMM @ h:mm A',
};

export const CALENDAR_TIME_FORMAT_LOWERCASE: Record<string, string> = {
  sameDay: '[today @] h:mm A',
  nextDay: '[tomorrow @] h:mm A',
  nextWeek: '[this] dddd Do of MMMM @ h:mm A',
  lastDay: '[yesterday @] h:mm A',
  lastWeek: '[last] dddd Do of MMMM @ h:mm A',
  sameElse: 'dddd Do of MMMM @ h:mm A',
};

export function getDefaultOpenTime(timezone: Timezone): moment.Moment {
  return moment.tz('08:00am', TIME_FORMAT, timezone);
}

export function getDefaultCloseTime(timezone: Timezone): moment.Moment {
  return moment.tz('06:00pm', TIME_FORMAT, timezone);
}

/** @deprecated Use getDefaultOpenTime instead **/
export const DEFAULT_OPEN_TIME: moment.Moment = moment('08:00am', TIME_FORMAT);

/** @deprecated Use getDefaultCloseTime instead **/
export const DEFAULT_CLOSE_TIME: moment.Moment = moment('06:00pm', TIME_FORMAT);

export const DEFAULT_INCREMENT = 10;
export const STEP_SIZE = 5;
export const MINIMUM_DURATION = 5;

/**
 * Formats a human readable period of time
 *  - Does NOT take into account times on different dates
 *  - Does NOT handle from time being after to time
 *  - Removes the am/pm in `from` segment if moments are in the same half of the day
 * Examples:
 *  2:00am - 3:00pm
 *  2:00 - 3:00pm
 */
export function formatTimeFromTo(
  from: moment.Moment,
  to: moment.Moment
): string {
  const isInSameHalfOfDay: boolean = from.format('a') === to.format('a');
  const fromFormat: string = isInSameHalfOfDay ? 'h:mm' : 'h:mma';

  const fromFormatted: string = from.format(fromFormat);
  const toFormatted: string = to.format('h:mma');

  return `${fromFormatted} - ${toFormatted}`;
}

/**
 * Format time string from 24_HR format to display format
 * @param timeString string (eg '13:00')
 * @return string (eg '1:00pm')
 */
export function formatTimeStringDisplay(
  timeString: string | moment.Moment
): string {
  if (!timeString) {
    return '';
  }
  return moment(timeString, TIME_FORMAT_24HR).format(TIME_FORMAT);
}

export function isValidDateString(value: string): boolean {
  const search =
    /^(0?[1-9]|[12][0-9]|3[01])[-/](0?[1-9]|1[0-2])[-/](?:\d{2}|\d{4})$/;
  return search.test(value);
}
