import {
  doc$,
  errorNil,
  find$,
  firstResult$,
  getParentDocRef,
  isObject,
  orderBy,
  where,
  type AtLeast,
  type CollectionReference,
  type DocumentReference,
  type ISoftDelete,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { of, type Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { type IPageTemplate } from '../page-template/page-template';
import { type IPathway } from '../pathway/pathway';
import { IPathwaySection } from '../pathway/pathway-section';
import { isSkill, type ISkill } from '../skill/skill';
import { type ITag } from '../tag/tag';
import { type IVendor } from './vendor';
import {
  VendorBundle,
  VendorBundleCollection,
  type IVendorBundle,
} from './vendor-bundle';
import { VendorBundleRelease } from './vendor-bundle-release';

export interface IBundlePathwaySection
  extends Pick<IPathwaySection, 'name' | 'uid' | 'dueDate'> {
  steps: DocumentReference<IBundleSkill>[];
}

export interface IBundleSkill
  extends Pick<
    ISkill,
    'name' | 'content' | 'media' | 'requiresTrainerVerification'
  > {
  tags: DocumentReference<IBundleTag>[];
}

export function isBundleSkill(item: unknown): item is IBundleSkill {
  return (
    !isSkill(item) &&
    isObject(item) &&
    'name' in item &&
    'content' in item &&
    'tags' in item &&
    Array.isArray(item.tags) &&
    'media' in item &&
    Array.isArray(item.media)
  );
}

export class BundleSkill {
  static storagePath(
    vendorRef: DocumentReference<IVendor>,
    bundleRef: DocumentReference<IVendorBundle>,
    skillRef: DocumentReference<IBundleSkill>
  ): string {
    return `${VendorBundle.storagePath(vendorRef, bundleRef)}/${
      VendorBundleCollection.Skills
    }/${skillRef.id}`;
  }

  static resolveSkill$(
    bundleSkill: WithRef<IBundleSkill>,
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<ISkill> | undefined> {
    return VendorBundle.resolveLatestRelease$(bundle).pipe(
      switchMap((release) => {
        if (!release) {
          return of(undefined);
        }

        return find$(
          VendorBundleRelease.skillCol(release),
          where('vendorSkillRef', '==', bundleSkill.ref)
        );
      })
    );
  }

  static bundle$(
    bundleSkill: WithRef<IBundleSkill>
  ): Observable<WithRef<IVendorBundle>> {
    return doc$(getParentDocRef<IVendorBundle>(bundleSkill.ref));
  }
}

export interface IBundlePathway
  extends ISoftDelete,
    Pick<IPathway, 'name' | 'description'> {
  sections: IBundlePathwaySection[];
  tags: DocumentReference<IBundleTag>[];
  imageUrl?: string;
}

export class VendorPathway {
  static init(overrides: AtLeast<IBundlePathway, 'name'>): IBundlePathway {
    return {
      deleted: false,
      description: '',
      sections: [],
      tags: [],
      ...overrides,
    };
  }

  static storagePath(
    vendorRef: DocumentReference<IVendor>,
    bundleRef: DocumentReference<IVendorBundle>,
    pathwayRef: DocumentReference<IBundlePathway>
  ): string {
    return `${VendorBundle.storagePath(vendorRef, bundleRef)}/${
      VendorBundleCollection.Pathways
    }/${pathwayRef.id}`;
  }

  static resolvePathway$(
    bundlePathway: WithRef<IBundlePathway>,
    bundle: WithRef<IVendorBundle>,
    orgPathwayCol: CollectionReference<IPathway>
  ): Observable<WithRef<IPathway> | undefined> {
    return VendorPathway.resolveLocalPathway$(
      bundlePathway.ref,
      orgPathwayCol
    ).pipe(
      switchMap((localPathway) =>
        localPathway && !bundle.readOnly
          ? of(localPathway)
          : VendorPathway.resolveVendorPathway$(bundlePathway, bundle)
      )
    );
  }

  static resolveVendorPathway$(
    bundlePathway: WithRef<IBundlePathway>,
    bundle: WithRef<IVendorBundle>
  ): Observable<WithRef<IPathway> | undefined> {
    return VendorBundle.resolveLatestRelease$(bundle).pipe(
      errorNil(),
      switchMap((release) =>
        VendorBundleRelease.resolvePathway$(release, bundlePathway)
      ),
      catchError(() => of(undefined))
    );
  }

  static resolveLocalPathway$(
    bundlePathwayRef: DocumentReference<IBundlePathway>,
    orgPathwayCol: CollectionReference<IPathway>
  ): Observable<WithRef<IPathway> | undefined> {
    // TODO: https://app.clickup.com/t/2tdkbny
    return firstResult$(
      orgPathwayCol,
      where('vendorPathwayRef', '==', bundlePathwayRef),
      orderBy('updatedAt', 'desc')
    ).pipe(map((pathway) => (pathway?.deleted ? undefined : pathway)));
  }
}

export type IBundleTemplate = Pick<IPageTemplate, 'name' | 'content'>;

export type IBundleTag = ITag;

export interface IBundleReleaseContent {
  pathways: DocumentReference<IBundlePathway>[];
  skills: DocumentReference<IBundleSkill>[];
  templates: DocumentReference<IBundleTemplate>[];
  tags: DocumentReference<IBundleTag>[];
}

export interface IBundleRelease {
  name: string;
  description: string;
  status: BundleReleaseStatus;
  releasedAt?: Timestamp;
  content: IBundleReleaseContent;
}

export enum BundleReleaseCollection {
  Pathways = 'pathways',
  Skills = 'skills',
  Templates = 'templates',
  Tags = 'tags',
  PublicPathways = 'publicPathways',
  PublicSkills = 'publicSkills',
}

export enum BundleReleaseStatus {
  Draft = 'draft',
  QueueRelease = 'queueRelease',
  Failed = 'failed',
  Released = 'released',
}
