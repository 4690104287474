import { inject, Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref } from '@angular/fire/storage';
import {
  filterUndefined,
  getError,
  type IProfile,
  type ObjectOfType,
  patchDoc,
  shareReplayCold,
  snapshot,
  type WithRef,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { combineLatest, from, noop, type Observable, of } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { NgFireMediaUploader } from '../ng-firestore-uploader';
import {
  type IOrganisationService,
  ORGANISATION_SERVICE,
} from '../ng-shared-config';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService } from 'libs/ng-auth/src/lib/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileImageService {
  private _imageCache: ObjectOfType<Observable<string>> = {};
  private _organisation: IOrganisationService = inject(ORGANISATION_SERVICE);

  constructor(private _auth: AuthService, private _storage: Storage) {
    const googleProfileImage$ = this._auth.authUser$.pipe(
      map((user) => user?.photoURL ?? undefined)
    );

    const user$ = this._organisation.user$.pipe(filterUndefined());

    const hasProfileImage$ = user$.pipe(
      map((user) => (user.profileImageURL ? true : false))
    );

    combineLatest([user$, googleProfileImage$, hasProfileImage$])
      .pipe(
        take(1),
        tap(([user, googleProfileImage, hasProfileImage]) => {
          if (hasProfileImage || !googleProfileImage) {
            return;
          }
          return from(this._uploadImage(user, googleProfileImage));
        })
      )
      .subscribe(noop);
  }

  getUserProfileImage$(user: IProfile): Observable<string | undefined> {
    if (!user.profileImageURL) {
      return of(undefined);
    }
    const imageURL = user.profileImageURL;

    if (this._imageCache[imageURL]) {
      return this._imageCache[imageURL];
    }

    if (imageURL.startsWith('https://')) {
      this._imageCache[imageURL] = of(imageURL).pipe(shareReplayCold());
    }

    try {
      const imageRef = ref(this._storage, imageURL);
      this._imageCache[imageURL] = from(getDownloadURL(imageRef)).pipe(
        filter((url): url is string => isString(url)),
        catchError(() => of(imageURL)),
        shareReplayCold()
      );
    } catch (error) {
      this._imageCache[imageURL] = of(imageURL).pipe(shareReplayCold());
    }

    return this._imageCache[imageURL];
  }

  private async _uploadImage(
    user: WithRef<IProfile>,
    imageUrl: string
  ): Promise<void> {
    try {
      const imageResponse = await fetch(imageUrl);
      if (!imageResponse.ok) {
        throw new Error('Failed to download image url');
      }
      const imageBlob: Blob = await imageResponse.blob();

      const uploader = new NgFireMediaUploader(
        this._storage,
        this._organisation.storagePath$
      );
      const upload = uploader.upload(imageBlob);
      const uploadComplete = await snapshot(upload.isUploadComplete$);
      if (!uploadComplete) {
        throw new Error('Failed to upload image');
      }

      const attachment = await snapshot(upload.attachment$);
      await patchDoc<IProfile>(user.ref, { profileImageURL: attachment.path });
    } catch (error) {
      throw new Error(getError(error));
    }
  }
}
