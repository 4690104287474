import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { type IOrganisation } from '@principle-theorem/level-up-core';
import { TypedFormControl, TypedFormGroup } from '@principle-theorem/ng-shared';

type OrganisationFormData = Pick<IOrganisation, 'name'>;

@Component({
  selector: 'lu-organisation-add-dialog',
  templateUrl: './organisation-add-dialog.component.html',
  styleUrls: ['./organisation-add-dialog.component.scss'],
})
export class OrganisationAddDialogComponent {
  form: TypedFormGroup<OrganisationFormData> =
    new TypedFormGroup<OrganisationFormData>({
      name: new TypedFormControl<string>(''),
    });

  constructor(
    private _dialogRef: MatDialogRef<
      OrganisationAddDialogComponent,
      Partial<IOrganisation> | undefined
    >
  ) {}

  canSave(): boolean {
    return this.form.valid && this.form.dirty;
  }

  submit(): void {
    this._dialogRef.close(this.form.getRawValue());
  }
}
