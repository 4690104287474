import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import { saveAs } from 'file-saver';

export interface ICSVExport<
  RecordFormat extends object,
  CSVFormat extends object
> {
  headers: string[];
  translate(records: RecordFormat[]): Promise<CSVFormat[]> | CSVFormat[];
}

@Injectable()
export class CSVExporterService {
  async download<RecordFormat extends object, CSVFormat extends object>(
    fileName: string,
    records: RecordFormat[],
    csvTranslator: ICSVExport<RecordFormat, CSVFormat>
  ): Promise<void> {
    const csvExporter = new ExportToCsv({
      filename: fileName,
      showLabels: true,
    });
    const rows = await csvTranslator.translate(records);
    const data = String(
      csvExporter.generateCsv(
        [
          csvTranslator.headers,
          ...rows.map((row) => Object.values(row) as unknown),
        ],
        true
      )
    );
    const fileBlob = new Blob([data], {
      type: 'csv',
    });
    saveAs(fileBlob, decodeURI(`${fileName}.csv`));
  }
}
