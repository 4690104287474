import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { type IUser } from '@principle-theorem/level-up-core';
import { AuthService, WorkspaceService } from '@principle-theorem/ng-auth';
import { ProfileImageService } from '@principle-theorem/ng-shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationService } from '../../../services/organisation.service';

@Component({
  selector: 'lu-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileMenuComponent {
  profileImage = inject(ProfileImageService);
  canChangeWorkspace$: Observable<boolean>;
  @Input() user: IUser;

  constructor(
    public auth: AuthService,
    private _workspace: WorkspaceService,
    public organisation: OrganisationService
  ) {
    this.canChangeWorkspace$ = this._workspace.workspaces$.pipe(
      map((workspaces) => workspaces.length > 1)
    );
  }
}
